<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs8>
        <view-title />
      </v-flex>
      <template v-if="multigroup_id">
        <v-flex xs7>
          <v-text-field
            single-line
            solo
            prepend-inner-icon="mdi-magnify"
            label="Search Sites"
            v-model="filter"
            flat
          />
        </v-flex>
        <v-flex xs4>
          <v-autocomplete
            :value="multigroup_id"
            :items="multigroupsList"
            item-text="name"
            item-value="id"
            label="Select App"
            return-object
            @change="setMultigroup"
            solo
            single-line
            flat
            color="blue"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-data-table
            :headers="sitesHeaders"
            :items="sites"
            :loading="loading"
            :search="filter"
            :pagination.sync="pagination"
            :no-data-text="loading ? 'Please wait while data is loading' : 'No sites found'"
          >
            <v-progress-linear
              v-slot:progress
              color="blue"
              indeterminate
              height="7"
            ></v-progress-linear>
            <template v-slot:items="props">
              <tr
                @click="
                  goToSite({
                    id: props.item.id,
                    name: props.item.name,
                    multigroupId: props.item.parentMultigroup.id,
                  })
                "
                class="clickable"
              >
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.parentMultigroup.name }}</td>
                <td>{{ props.item.address.country }}</td>
                <td>{{ props.item.address.city }}</td>
                <td>{{ props.item.platform }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { defaultAppID } from '@/constants';

export default {
  name: 'orders-multigroup',
  props: ['multigroup_id'],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isSiteOperator) {
        vm.$router.replace({
          name: 'orders-sites',
        });
      }
    });
  },

  data: () => ({
    inFocus: false,
    filter: '',
    headers: [
      {
        text: 'App',
        align: 'left',
        value: 'name',
      },
    ],
    sitesHeaders: [
      {
        text: 'Site Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Site App',
        align: 'left',
        value: 'parentMultigroup.name',
      },
      {
        text: 'Country',
        align: 'left',
        value: 'address.country',
      },
      {
        text: 'City',
        align: 'left',
        value: 'address.city',
      },
      {
        text: 'Platform',
        align: 'left',
        value: 'platform',
      },
    ],
    pagination: {
      rowsPerPage: 10,
    },
    appID: localStorage.orderDefaultAppID ? localStorage.orderDefaultAppID : defaultAppID,
  }),

  computed: {
    ...mapState('sites', ['multigroups', 'multigroupMap', 'siteMap']),
    ...mapGetters('adminPanel', ['isSiteOperator']),
    ...mapGetters('sites', {
      isDHSite: 'isDHSite',
    }),

    multigroupsList() {
      return [
        { name: 'All', id: 'all' },
        ...this.multigroups.map((i) => ({
          name: i.name,
          id: i.id,
        })),
      ];
    },

    sites() {
      if (this.multigroup_id !== 'all') {
        const groups =
          (this.multigroupMap[this.multigroup_id] &&
            this.multigroupMap[this.multigroup_id].groups) ||
          [];
        groups.forEach((g) => {
          g.platform = this.isDHSite(g) ? 'DH' : 'CDL';
          if (!g.address) {
            g.address = { country: 'N/A', city: 'N/A' };
          }
        });
        return groups;
      }
      const sites = Object.values(this.siteMap);
      sites.forEach((g) => {
        g.platform = this.isDHSite(g) ? 'DH' : 'CDL';
        if (!g.address) {
          g.address = { country: 'N/A', city: 'N/A' };
        }
      });
      return sites;
    },
    selectedApp() {
      return this.$route.params.multigroup_id;
    },
    loading() {
      if (this.appID === 'all') return !this.siteMap;
      return !(
        this.multigroupMap[this.multigroup_id] && this.multigroupMap[this.multigroup_id].groups
      );
    },
  },

  methods: {
    ...mapActions('sites', ['fetchMultigroup']),
    setMultigroup({ id }) {
      if (localStorage.orderDefaultAppID !== id) {
        this.filter = '';
        this.goToMultiGroup(id);
      }
      if (!localStorage.orderDefaultAppID || localStorage.orderDefaultAppID !== id) {
        localStorage.orderDefaultAppID = id;
        this.appID = id;
      }
    },
    goToSite({ id }) {
      this.$router.push({
        name: 'orders-site',
        params: {
          site_id: id,
        },
      });
    },
    goToMultiGroup(id) {
      this.$router.push({
        name: 'orders-multigroup',
        params: {
          multigroup_id: id,
        },
      });
    },
    switchState() {
      this.inFocus = true;
    },
  },
  watch: {
    selectedApp(appIdValue) {
      if (!appIdValue) {
        this.goToMultiGroup(this.appID);
      } else {
        this.fetchMultigroup({ id: appIdValue });
      }
    },
  },
  async mounted() {
    if (this.$route.params.multigroup_id !== this.appID) {
      this.goToMultiGroup(this.appID);
    }

    if (this.$route.params.multigroup_id === 'all') {
      await this.$store.dispatch('sites/fetchAll');
    } else {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: 'Orders',
        to: { name: 'orders-multigroup' },
        last: true,
      });
    }
  },
};
</script>
