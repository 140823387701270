import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
          _vm.multigroup_id
            ? [
                _c(
                  VFlex,
                  { attrs: { xs7: "" } },
                  [
                    _c(VTextField, {
                      attrs: {
                        "single-line": "",
                        solo: "",
                        "prepend-inner-icon": "mdi-magnify",
                        label: "Search Sites",
                        flat: ""
                      },
                      model: {
                        value: _vm.filter,
                        callback: function($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  VFlex,
                  { attrs: { xs4: "" } },
                  [
                    _c(VAutocomplete, {
                      attrs: {
                        value: _vm.multigroup_id,
                        items: _vm.multigroupsList,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Select App",
                        "return-object": "",
                        solo: "",
                        "single-line": "",
                        flat: "",
                        color: "blue"
                      },
                      on: { change: _vm.setMultigroup }
                    })
                  ],
                  1
                ),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      VDataTable,
                      {
                        attrs: {
                          headers: _vm.sitesHeaders,
                          items: _vm.sites,
                          loading: _vm.loading,
                          search: _vm.filter,
                          pagination: _vm.pagination,
                          "no-data-text": _vm.loading
                            ? "Please wait while data is loading"
                            : "No sites found"
                        },
                        on: {
                          "update:pagination": function($event) {
                            _vm.pagination = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c(
                                    "tr",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.goToSite({
                                            id: props.item.id,
                                            name: props.item.name,
                                            multigroupId:
                                              props.item.parentMultigroup.id
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.name))
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            props.item.parentMultigroup.name
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.address.country)
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.address.city))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.platform))
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          537805197
                        )
                      },
                      [
                        _c(VProgressLinear, {
                          attrs: {
                            color: "blue",
                            indeterminate: "",
                            height: "7"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "progress",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            63074868
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }